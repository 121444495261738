.StateButtonMob {
    width: 100%;
    min-height: 5rem;
    text-align: center;
    background-color: black;
    color: white;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: column;
}

.StateButtonMob:hover {
    background-color: rgb(39, 39, 39);
    cursor: pointer;
}

.StateButtonMob p {
    position: absolute;
    height: 5rem;
    display: flex;
    align-items: center;
}
