#ExpandButton {
    position: absolute;
    align-self: flex-end;
    left: 0px;
    width: 100%;
    display: flex;
    justify-content: center;
}

#ExpandButton button {
    width: 10rem;
    border: none;
    background-color: transparent;
}

#ExpandButton img {
    width: 7rem;
}

#ExpandButton img:hover {
    cursor: pointer;
}