.LocationButtonMob {
    width: 100%;
}
.LocationButtonMob button {
    padding-top: 1rem;
    padding-bottom: 1rem;
    height: 5rem;
    width: 100%;
    background-color: rgb(43, 43, 43);
    color: rgb(255, 255, 255);
    border: none;
}
