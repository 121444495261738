#WhatsappButton {
    position: fixed;
    bottom: 1.5rem;
    right: 1.5rem;
    padding: 10px;
    z-index: 55;
}

#WhatsappButton button {
    background-color: transparent;
    border: none;
}

#WhatsappButton button:hover {
    cursor: pointer;
}

#WhatsappButton img {
    width: 5rem;
}