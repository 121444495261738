.GalleryMenu {
    position: absolute;
    z-index: 10;
    align-self: flex-end;
    justify-self: center;
    color: red;
    background-color: rgb(255, 255, 255);
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    opacity: 0.7;
    margin-bottom: 0.5rem;
    padding: 3px;
}

.GalleryMenu img {
    width: 0.5rem;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
}

.GalleryMenu img:hover {
    cursor: pointer;
}