#MapSection {
    width: 100%;
    margin-top: 1rem;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
}

#MapSection iframe {
    max-width: 24rem;
    width: 100%;
    height: 15rem;
}

#mapWrapper {
    max-width: 24rem;
    width: 90%;
    margin: 0.5rem;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.writtenAddress {
    width: 24rem;
    height: 5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    color: white;
}

#LocationImage {
    margin: 0.5rem;
    height: 20rem;
    max-width: 24rem;
    width: 90%;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
}

#LocationImage img {
    transition: width 0.7s ease-in-out;
}

@media only screen and (max-width: 480px) {
    #MapSection {
        margin-top: 0px;
    }
}