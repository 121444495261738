#MenuButtons {
    position: absolute;
    right: 0;
    top: inherit;
    background-color: black;
    width: 100%;
    transition: transform 0.5s ease-in-out;
    z-index: -20;
}

#MenuButtons button {
    width: 100%;
    border: none;
    background-color: transparent;
    color: white;
    height: 4rem;
    z-index: -20;
}