#DesktopMenu {
    height: inherit;
}

#DesktopMenu button {
    height: inherit;
    background-color: transparent;
    border: none;
    color: white;
    width: 12vw;
    max-width: 20rem;
}

#DesktopMenu button:hover {
    cursor: pointer;
    background-color: rgb(43, 43, 43);
}