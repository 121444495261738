.SlidingGallery {
    width: 400px;
}

.galleryImages {
    width: 400px;
    display: grid;
    overflow: hidden;
    grid-template-areas: 'image';
    grid-template-columns: 1fr;
    grid-template-rows: 1fr;
}