#MobileSelector {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
}

#openMenu {
    width: 100%;
    height: 5rem;
    border: none;
    background-color: black;
    color: white;
    font-size: 1.5rem;
    text-align: center;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

#MobileSelector button:hover {
    background-color: rgb(61, 61, 61);
    cursor: pointer;
}
#StateButtons {
    width: 100%;
}

#expandIcon {
    height: 5rem;
    transition: transform 0.3s ease-in-out;
}