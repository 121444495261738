.LocationButton {
    width: 100%;
}
.LocationButton button {
    padding-top: 1rem;
    font-size: 0.7rem;
    padding-bottom: 1rem;
    width: 100%;
    background-color: rgb(36, 36, 36);
    color: white;
    border: none;
}

.LocationButton button:hover {
    cursor: pointer;
    background-color: rgb(73, 73, 73);
}