#Nosotros {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin-top: 1rem;
    overflow-x: hidden;
    color: white;
}

#Nosotros h2 {
    font-size: 2rem;
    color: white;
    height: 5rem;
    width: 100%;
    border-radius: 5px;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgb(20, 20, 20);
}

#Nosotros-Content {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
}

#staffImage {
    width: 100%;
    max-width: 26rem;
    transition: opacity 0.5s ease-in-out;
}

#prevStaffImage {
    width: 100%;
    max-width: 26rem;
    position: absolute;
}

#Nosotros-text {
    margin: 1rem;
    width: 100%;
    max-width: 26rem;
    font-size: 1.3rem;
    display: flex;
    justify-content: center;
    align-items: center;
}

#Nosotros-text p {
    line-height: 2rem;
    text-align: justify;
}

@media only screen and (max-width: 480px) {
    #Nosotros-text p {
        width: 90%;
    }
    #Nosotros h2 {
        border-radius: 0px;
    }
}

@media only screen and (max-width: 768px) {
	#staffImage {
        max-width: 24rem;
        width: 100%;
    }
    #prevStaffImage {
        max-width: 24rem;
        width: 100%;
    }
    #Nosotros-text {
        max-width: 24rem;
        width: 100%;
        font-size: 1.2rem;
    }
    #Nosotros h2 {
        margin-bottom: 0rem;
    }

    #Nosotros{
        margin-top: 0px;
    }
}