#ProductGallery {
    height: 20vw;
    width: 100%;
    display: flex;
}

@media only screen and (max-width: 480px) {
    #ProductGallery {
        height: 100vw;
    }
}