.ProductImage {
    width: 25%;     
    overflow: hidden;
    background-position: center;
    background-size: cover;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    text-align: center;
    color: white;
}

.ProductImage:hover {
    cursor: pointer;
}

.ProductImage h2 {
    font-size: 2rem;
    width: 80%;
}
.ProductImage h3 {
    font-size: 1.5rem;
}

@media only screen and (max-width: 480px) {
    .ProductImage {
        width: 100%;
        height: inherit;
        position: absolute;
        display: none;
    }
}