.StateButton {
    width: 15vw;
    min-width: 12rem;
    min-height: 5rem;
    text-align: center;
    color: white;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: column;
}

.StateButton:hover {
    background-color: rgb(54, 54, 54);
    cursor: pointer;
}

.StateButton p {
    position: absolute;
    height: 5rem;
    display: flex;
    align-items: center;
}

.expandIconDesk {
    transition: transform 0.3s ease-in-out;
    height: 2rem;
}