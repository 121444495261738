#Footer {
    width: 100%;
    background-color: black;
    height: 5rem;
    color: white;
    display: flex;
    justify-content: space-between;
    align-items:center;
}

#FooterSocials {
    width: 33.33%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
}

#mail-svg {
    height: 47% !important;
}

#phone-svg {
    height: 47% !important;
}

#FooterSocials img {
    height: 50%;
}

#FooterSocials img:hover {
    cursor: pointer;
}

#FooterText {
    text-align: center;
    width: 66.666%;
    display: flex;
}
#FooterText p {
    width: 100%;
}

#FEVIfirma {
    color: rgb(201, 255, 184);
}
@media only screen and (max-width: 480px) {
    #FooterText {
        width: 50%;
        flex-direction: column;
    }
    #FooterSocials {
        width: 50%;
    }
}
