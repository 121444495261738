#StateSelector {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-direction: row;
    height: 5rem;
    width: 100%;
    background-color: rgb(36, 36, 36);
    z-index: 50;
    /* overflow: hidden; */
}

#StateSelectorWrapper {
    width: 100%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
}
#StateSelectorWrapper h2 {
    background-color: black;
    color: white;
    font-size: 2rem;
    width: 100%;
    text-align: center;
    height: 5rem;
    display: flex;
    align-items: center;
    justify-content: center;
}