#NavBarLogo {
    height: inherit;
    /* background-color: aqua; */
    justify-content: left;
    width: 100%;
    max-width: 25rem;
    align-items: center;
    z-index: 10;
    background-color: black;
}
#NavBarLogo img {
    height: inherit;
    /* background-color: green; */
    margin-left: 2rem;
}

#NavBarLogo img:hover {
    cursor: pointer;
}