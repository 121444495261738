#MobileMenu {
    width: 100%;
    background-color: black;
}
#MobileMenu img {
    width: 4rem;
    margin-right: 1rem;
    z-index: 10;
    background-color: black;
}

#MobileMenu img:hover {
    cursor: pointer;
}