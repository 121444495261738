#navBar {
    position: fixed;
    width: 100%;
    background-color: black;
    height: 5rem;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    z-index: 100;
}