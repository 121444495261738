.ImageComing {
    margin: 0px;
    padding: 0px;
    grid-area: image;
}

.ImageComing img {
    width: 100%;
    max-width: 26rem;
}

.justMounted {
    transform: translate(400px);
}

.moveToThePosition {
    transition: transform 1s ease-in-out;
    transform: translate(0px);
}

.moveToTheCenter {
    transform: translate(0px);
}

.moveOutside {
    transition: transform 1s ease-in-out;
    transform: translate(-400px);
}