#Banner {
    height: 100vh;
    width: 100%;
    background-position: center;
    background-size:cover;
    background-image: 
    linear-gradient(
      rgba(0, 0, 0, 0.45), 
      rgba(0, 0, 0, 0.45)
    ),
    url('./resources/background.webp');
    display: flex;
    justify-content: center;
    margin: 0px;
}

#BannerTexts {
    align-self: center;
    text-align: center;
    display: flex;
    align-items: center;
    flex-direction: column;
}

#BannerTexts img  {
    width: 35rem;

}

#BannerTexts h2 {
    color: white;
    font-size: 1.1rem;
}

@media only screen and (max-width: 480px) {
    #BannerTexts img {
        width: 90vw;
    }

    #BannerTexts h2 {
        font-size: 0.9rem;
        width: 80%;
    }
}